<template>
<section class="content-header">
    <h1>
        {{pageTitle}}
          <Popper arrow>
            <i class="fa fa-question-circle text-info help-icon"></i>
            <template #content>
            <div class="tooltiptext-container"  v-html="tooltipText">
            </div>
            </template>
         </Popper>
    </h1>
   <!--<ol class="breadcrumb">
        <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
        <li class="active">{{pageSection}}</li>
        <li class="active"> {{pageTitle}}</li>
    </ol>--> 
    <p> {{pageDes}}</p>
</section>
</template>
<script>
import Popper from "vue3-popper";

var tooltips = [];

tooltips['My Message Inbox'] = '<p style="max-width:420px">The Message List shows all questions that have been assigned to you. When new messages are assigned to you they will automatically appear in this list. To assign a new message to yourself, click the Get Next button next to your name in the top right corner of the page. The number of available messages is displayed in a red badge on top of the button. <br/><br/>Select a message to compose a response in the panel below. The interaction will be displayed using the Action Mode for questions. Learn more about the Action Mode in the <a href="https://www.iservice.info/guides/users/understanding-interactions.html?anchor=actionmode">user guide</a>.</p>';

tooltips['New Contact'] = '<p> This page only displays contact properties for your segments, and properties designated as shared.Segment Membership determines the agents that can view the contact. Make sure you set Segment Membership so the appropriate agents can view this contact.</p>';

tooltips['Manage Messages'] = '<p><div>This page lists the questions waiting for an answer and proposed answers waiting for approval for all the segments that you can access. Select the topics that you need to monitor from the Topic List to display their questions in the message list to the right. <br><br>Use the mass update panel to take actions on multiple messages at once by selecting them from the Message List. When you send answers from the mass update panel, each contact will receive their own personalized response.<br><br>The Message List is updated automatically as changes occur. There is no need to reload the page to see new messages, assignment changes, etc.<br><br>Read the <a href="https://www.iservice.info/guides/users/manage-messages.html">user guide</a> to learn more about this page.</div></p>';

tooltips['Manage Chats'] = '<div>This page shows all waiting and active chats, and the number of chat agents available for each topic (hover over the number next to the topic to see the list of available chat agents). Select topics to see the chats in their queues, and expand a chat to view its contents.<br><br>The Chat List is updated automatically as changes occur. There is no need to reload the page to see new chats. Expand a chat to see the real-time display of the conversation between your agent and the customer. You can take actions on the chat (end, change topic, reassign, etc.) from inside the chat details panel.<br><br>Read the <a href="https://www.iservice.info/guides/users/manage-chats.html">user guide</a> to learn more about this page.</div>';

tooltips['OpenID'] = '<div>iService supports openID integration to simplify the agent login process. Enter your openID Connect credentials into this page and the agent login page will be updated to include the appropriate button. <br/><br/> For more information, see the <a href="https://www.iservice.info/guides/users/open-id.html">iService User Guide.</a></div>';

tooltips['Credit Memo Approval'] = "<div>To accept the credit memo request, click the Approve Credit button. To reject the request, click the Reject Credit' button and enter a short explanation for your denial. Your explanation will be emailed to the person that requested the credit memo.<br><br>There are four conditions that can prevent the form from loading. Visit the<a href='https://www.iservice.info/guides/users/approve-credit-memo.html'>user guide</a>to learn more about these conditions and the workaround for each scenario.<br><br><ul><li>Ticket assigned to someone else</li><li>Ticket not assigned to anyone</li><li>Ticket already resolved</li><li>Ticket isn't a credit memo</li><ul></ul></ul></div>";


tooltips['Report A Bug'] = "<div>Use this page to report a bug to iService technical support. Please be as detailed as possible when describing what happened and how it affected your workflow.</div>";


tooltips['My Estimates'] = "<div>This page lists all estimates that are in progress that are currently assigned to you. Closed estimates can be located through <a href='/pages/interaction-search.html'>Search Interactions</a>.</div>";


export default {
  name: 'BreadcrumbComponents',
  props: ['pageTitle','pageSection','pageDes'],
  components: {
        Popper
    },
  data() {
        return {
            tooltipText: tooltips[this.pageTitle]
        }
    },
}
</script>
